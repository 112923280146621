exports.components = {
  "component---src-data-pages-404-index-jsx": () => import("./../../../src/data/pages/404/index.jsx" /* webpackChunkName: "component---src-data-pages-404-index-jsx" */),
  "component---src-data-pages-contact-index-jsx": () => import("./../../../src/data/pages/contact/index.jsx" /* webpackChunkName: "component---src-data-pages-contact-index-jsx" */),
  "component---src-data-pages-cookiebeleid-index-jsx": () => import("./../../../src/data/pages/cookiebeleid/index.jsx" /* webpackChunkName: "component---src-data-pages-cookiebeleid-index-jsx" */),
  "component---src-data-pages-diarree-bij-kinderen-index-jsx": () => import("./../../../src/data/pages/diarree-bij-kinderen/index.jsx" /* webpackChunkName: "component---src-data-pages-diarree-bij-kinderen-index-jsx" */),
  "component---src-data-pages-diarree-op-reis-behandeling-van-reizigersdiarree-index-jsx": () => import("./../../../src/data/pages/diarree-op-reis/behandeling-van-reizigersdiarree/index.jsx" /* webpackChunkName: "component---src-data-pages-diarree-op-reis-behandeling-van-reizigersdiarree-index-jsx" */),
  "component---src-data-pages-diarree-op-reis-index-jsx": () => import("./../../../src/data/pages/diarree-op-reis/index.jsx" /* webpackChunkName: "component---src-data-pages-diarree-op-reis-index-jsx" */),
  "component---src-data-pages-diarree-op-reis-reisapotheek-index-jsx": () => import("./../../../src/data/pages/diarree-op-reis/reisapotheek/index.jsx" /* webpackChunkName: "component---src-data-pages-diarree-op-reis-reisapotheek-index-jsx" */),
  "component---src-data-pages-diarree-op-reis-reizigersdiaree-vermijden-index-jsx": () => import("./../../../src/data/pages/diarree-op-reis/reizigersdiaree-vermijden/index.jsx" /* webpackChunkName: "component---src-data-pages-diarree-op-reis-reizigersdiaree-vermijden-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-buikgriep-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/buikgriep/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-buikgriep-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-chronische-diarree-diagnose-prikkelbare-darm-voeding-bij-prikkelbare-darm-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/chronische-diarree/diagnose-prikkelbare-darm/voeding-bij-prikkelbare-darm/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-chronische-diarree-diagnose-prikkelbare-darm-voeding-bij-prikkelbare-darm-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-chronische-diarree-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/chronische-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-chronische-diarree-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-diarree-tijdens-uw-menstruatie-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/diarree-tijdens-uw-menstruatie/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-diarree-tijdens-uw-menstruatie-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-eenartsraadplegen-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/eenartsraadplegen/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-eenartsraadplegen-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/gezonde-eetgewoonten/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-recepten-voor-een-evenwichtige-voeding-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/gezonde-eetgewoonten/recepten-voor-een-evenwichtige-voeding/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-recepten-voor-een-evenwichtige-voeding-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-voedselallergie-235-n-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-voedselallergie-235-n-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-voedselvergiftiging-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-voedselvergiftiging-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-norovirus-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/norovirus/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-norovirus-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-oorzaken-van-diarree-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/oorzaken-van-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-oorzaken-van-diarree-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-stress-en-diarree-diarree-examen-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/stress-en-diarree/diarree-examen/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-stress-en-diarree-diarree-examen-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-stress-en-diarree-hoge-werklast-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/stress-en-diarree/hoge-werklast/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-stress-en-diarree-hoge-werklast-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-stress-en-diarree-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/stress-en-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-stress-en-diarree-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-tips-voor-de-behandeling-van-diarree-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-tips-voor-de-behandeling-van-diarree-index-jsx" */),
  "component---src-data-pages-fr-404-index-jsx": () => import("./../../../src/data/pages/fr/404/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-404-index-jsx" */),
  "component---src-data-pages-fr-comment-agit-imodium-index-jsx": () => import("./../../../src/data/pages/fr/comment-agit-imodium/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-comment-agit-imodium-index-jsx" */),
  "component---src-data-pages-fr-comment-agit-imodium-la-substance-active-loperamide-index-jsx": () => import("./../../../src/data/pages/fr/comment-agit-imodium/la-substance-active-loperamide/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-comment-agit-imodium-la-substance-active-loperamide-index-jsx" */),
  "component---src-data-pages-fr-contact-index-jsx": () => import("./../../../src/data/pages/fr/contact/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-contact-index-jsx" */),
  "component---src-data-pages-fr-diarrhee-chez-les-enfants-index-jsx": () => import("./../../../src/data/pages/fr/diarrhee-chez-les-enfants/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-diarrhee-chez-les-enfants-index-jsx" */),
  "component---src-data-pages-fr-diarrhee-en-voyage-eviter-la-diarrhee-du-voyageur-index-jsx": () => import("./../../../src/data/pages/fr/diarrhee-en-voyage/eviter-la-diarrhee-du-voyageur/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-diarrhee-en-voyage-eviter-la-diarrhee-du-voyageur-index-jsx" */),
  "component---src-data-pages-fr-diarrhee-en-voyage-index-jsx": () => import("./../../../src/data/pages/fr/diarrhee-en-voyage/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-diarrhee-en-voyage-index-jsx" */),
  "component---src-data-pages-fr-diarrhee-en-voyage-pharmacie-de-voyage-index-jsx": () => import("./../../../src/data/pages/fr/diarrhee-en-voyage/pharmacie-de-voyage/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-diarrhee-en-voyage-pharmacie-de-voyage-index-jsx" */),
  "component---src-data-pages-fr-diarrhee-en-voyage-traitement-de-la-diarrhee-du-voyageur-index-jsx": () => import("./../../../src/data/pages/fr/diarrhee-en-voyage/traitement-de-la-diarrhee-du-voyageur/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-diarrhee-en-voyage-traitement-de-la-diarrhee-du-voyageur-index-jsx" */),
  "component---src-data-pages-fr-faq-questions-diarrhee-gene-de-demander-un-traitement-antidiarrheique-a-la-pharmacie-index-jsx": () => import("./../../../src/data/pages/fr/faq-questions-diarrhee/gene-de-demander-un-traitement-antidiarrheique-a-la-pharmacie/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-faq-questions-diarrhee-gene-de-demander-un-traitement-antidiarrheique-a-la-pharmacie-index-jsx" */),
  "component---src-data-pages-fr-faq-questions-diarrhee-imodium-peut-il-provoquer-une-constipation-index-jsx": () => import("./../../../src/data/pages/fr/faq-questions-diarrhee/imodium-peut-il-provoquer-une-constipation/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-faq-questions-diarrhee-imodium-peut-il-provoquer-une-constipation-index-jsx" */),
  "component---src-data-pages-fr-faq-questions-diarrhee-index-jsx": () => import("./../../../src/data/pages/fr/faq-questions-diarrhee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-faq-questions-diarrhee-index-jsx" */),
  "component---src-data-pages-fr-faq-questions-diarrhee-la-diarrhee-que-faire-index-jsx": () => import("./../../../src/data/pages/fr/faq-questions-diarrhee/la-diarrhee-que-faire/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-faq-questions-diarrhee-la-diarrhee-que-faire-index-jsx" */),
  "component---src-data-pages-fr-faq-questions-diarrhee-le-diarrhee-avec-des-crampes-et-des-ballonnements-index-jsx": () => import("./../../../src/data/pages/fr/faq-questions-diarrhee/le-diarrhee-avec-des-crampes-et-des-ballonnements/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-faq-questions-diarrhee-le-diarrhee-avec-des-crampes-et-des-ballonnements-index-jsx" */),
  "component---src-data-pages-fr-faq-questions-diarrhee-pourquoi-ai-je-souvent-la-diarrhee-pendant-mes-regles-index-jsx": () => import("./../../../src/data/pages/fr/faq-questions-diarrhee/pourquoi-ai-je-souvent-la-diarrhee-pendant-mes-regles/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-faq-questions-diarrhee-pourquoi-ai-je-souvent-la-diarrhee-pendant-mes-regles-index-jsx" */),
  "component---src-data-pages-fr-faq-questions-diarrhee-pourquoi-est-ce-qon-attrape-des-maux-de-ventre-quand-on-est-nerveux-index-jsx": () => import("./../../../src/data/pages/fr/faq-questions-diarrhee/pourquoi-est-ce-qon-attrape-des-maux-de-ventre-quand-on-est-nerveux/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-faq-questions-diarrhee-pourquoi-est-ce-qon-attrape-des-maux-de-ventre-quand-on-est-nerveux-index-jsx" */),
  "component---src-data-pages-fr-faq-questions-diarrhee-pourquoi-la-diarrhee-quand-je-mange-et-bois-en-grande-quantite-index-jsx": () => import("./../../../src/data/pages/fr/faq-questions-diarrhee/pourquoi-la-diarrhee-quand-je-mange-et-bois-en-grande-quantite/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-faq-questions-diarrhee-pourquoi-la-diarrhee-quand-je-mange-et-bois-en-grande-quantite-index-jsx" */),
  "component---src-data-pages-fr-faq-questions-diarrhee-quelle-est-la-cause-de-la-diarrhee-aigue-de-courte-duree-index-jsx": () => import("./../../../src/data/pages/fr/faq-questions-diarrhee/quelle-est-la-cause-de-la-diarrhee-aigue-de-courte-duree/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-faq-questions-diarrhee-quelle-est-la-cause-de-la-diarrhee-aigue-de-courte-duree-index-jsx" */),
  "component---src-data-pages-fr-index-jsx": () => import("./../../../src/data/pages/fr/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-causes-de-la-diarrhee-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/causes-de-la-diarrhee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-causes-de-la-diarrhee-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-conseils-pour-le-traitement-des-symptomes-de-la-diarrhee-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-conseils-pour-le-traitement-des-symptomes-de-la-diarrhee-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-consultez-votre-medecin-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/consultez-votre-medecin/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-consultez-votre-medecin-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-des-habitudes-alimentaires-saines-allergies-alimentaires-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/des-habitudes-alimentaires-saines/allergies-alimentaires/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-des-habitudes-alimentaires-saines-allergies-alimentaires-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-des-habitudes-alimentaires-saines-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/des-habitudes-alimentaires-saines/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-des-habitudes-alimentaires-saines-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-des-habitudes-alimentaires-saines-intoxication-alimentaire-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/des-habitudes-alimentaires-saines/intoxication-alimentaire/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-des-habitudes-alimentaires-saines-intoxication-alimentaire-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-des-habitudes-alimentaires-saines-recettes-sante-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/des-habitudes-alimentaires-saines/recettes-sante/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-des-habitudes-alimentaires-saines-recettes-sante-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-infection-virale-ou-bacterienne-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/infection-virale-ou-bacterienne/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-infection-virale-ou-bacterienne-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-la-diarrhee-chronique-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/la-diarrhee-chronique/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-la-diarrhee-chronique-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-la-diarrhee-pendant-les-regles-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/la-diarrhee-pendant-les-regles/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-la-diarrhee-pendant-les-regles-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-norovirus-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/norovirus/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-norovirus-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-stress-et-diarrhee-diarrhee-examens-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/stress-et-diarrhee/diarrhee-examens/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-stress-et-diarrhee-diarrhee-examens-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-stress-et-diarrhee-emploi-du-temps-charge-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/stress-et-diarrhee/emploi-du-temps-charge/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-stress-et-diarrhee-emploi-du-temps-charge-index-jsx" */),
  "component---src-data-pages-fr-la-diarrhee-stress-et-diarrhee-index-jsx": () => import("./../../../src/data/pages/fr/la-diarrhee/stress-et-diarrhee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-diarrhee-stress-et-diarrhee-index-jsx" */),
  "component---src-data-pages-fr-la-verite-sur-la-diarrhee-diagnostic-du-syndrome-du-colon-irritable-alimentation-en-cas-de-syndrome-du-colon-irritable-index-jsx": () => import("./../../../src/data/pages/fr/la-verite-sur-la-diarrhee/diagnostic-du-syndrome-du-colon-irritable/alimentation-en-cas-de-syndrome-du-colon-irritable/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-verite-sur-la-diarrhee-diagnostic-du-syndrome-du-colon-irritable-alimentation-en-cas-de-syndrome-du-colon-irritable-index-jsx" */),
  "component---src-data-pages-fr-lexique-abdominal-index-jsx": () => import("./../../../src/data/pages/fr/lexique/abdominal/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-abdominal-index-jsx" */),
  "component---src-data-pages-fr-lexique-allergie-index-jsx": () => import("./../../../src/data/pages/fr/lexique/allergie/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-allergie-index-jsx" */),
  "component---src-data-pages-fr-lexique-antibiotiques-index-jsx": () => import("./../../../src/data/pages/fr/lexique/antibiotiques/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-antibiotiques-index-jsx" */),
  "component---src-data-pages-fr-lexique-antispasmodique-index-jsx": () => import("./../../../src/data/pages/fr/lexique/antispasmodique/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-antispasmodique-index-jsx" */),
  "component---src-data-pages-fr-lexique-bacteries-index-jsx": () => import("./../../../src/data/pages/fr/lexique/bacteries/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-bacteries-index-jsx" */),
  "component---src-data-pages-fr-lexique-ballonnements-index-jsx": () => import("./../../../src/data/pages/fr/lexique/ballonnements/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-ballonnements-index-jsx" */),
  "component---src-data-pages-fr-lexique-constipation-index-jsx": () => import("./../../../src/data/pages/fr/lexique/constipation/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-constipation-index-jsx" */),
  "component---src-data-pages-fr-lexique-deshydratation-index-jsx": () => import("./../../../src/data/pages/fr/lexique/deshydratation/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-deshydratation-index-jsx" */),
  "component---src-data-pages-fr-lexique-diarrhee-aigue-index-jsx": () => import("./../../../src/data/pages/fr/lexique/diarrhee-aigue/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-diarrhee-aigue-index-jsx" */),
  "component---src-data-pages-fr-lexique-diarrhee-chronique-index-jsx": () => import("./../../../src/data/pages/fr/lexique/diarrhee-chronique/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-diarrhee-chronique-index-jsx" */),
  "component---src-data-pages-fr-lexique-diarrhee-index-jsx": () => import("./../../../src/data/pages/fr/lexique/diarrhee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-diarrhee-index-jsx" */),
  "component---src-data-pages-fr-lexique-flatulence-index-jsx": () => import("./../../../src/data/pages/fr/lexique/flatulence/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-flatulence-index-jsx" */),
  "component---src-data-pages-fr-lexique-gastro-enterite-index-jsx": () => import("./../../../src/data/pages/fr/lexique/gastro-enterite/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-gastro-enterite-index-jsx" */),
  "component---src-data-pages-fr-lexique-index-jsx": () => import("./../../../src/data/pages/fr/lexique/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-index-jsx" */),
  "component---src-data-pages-fr-lexique-intestins-index-jsx": () => import("./../../../src/data/pages/fr/lexique/intestins/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-intestins-index-jsx" */),
  "component---src-data-pages-fr-lexique-laxatifs-index-jsx": () => import("./../../../src/data/pages/fr/lexique/laxatifs/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-laxatifs-index-jsx" */),
  "component---src-data-pages-fr-lexique-loperamide-index-jsx": () => import("./../../../src/data/pages/fr/lexique/loperamide/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-loperamide-index-jsx" */),
  "component---src-data-pages-fr-lexique-menstruation-index-jsx": () => import("./../../../src/data/pages/fr/lexique/menstruation/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-menstruation-index-jsx" */),
  "component---src-data-pages-fr-lexique-mucus-index-jsx": () => import("./../../../src/data/pages/fr/lexique/mucus/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-mucus-index-jsx" */),
  "component---src-data-pages-fr-lexique-nausee-index-jsx": () => import("./../../../src/data/pages/fr/lexique/nausee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-nausee-index-jsx" */),
  "component---src-data-pages-fr-lexique-norovirus-index-jsx": () => import("./../../../src/data/pages/fr/lexique/norovirus/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-norovirus-index-jsx" */),
  "component---src-data-pages-fr-lexique-nutriments-index-jsx": () => import("./../../../src/data/pages/fr/lexique/nutriments/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-nutriments-index-jsx" */),
  "component---src-data-pages-fr-lexique-peristaltisme-index-jsx": () => import("./../../../src/data/pages/fr/lexique/peristaltisme/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-peristaltisme-index-jsx" */),
  "component---src-data-pages-fr-lexique-rehydratation-index-jsx": () => import("./../../../src/data/pages/fr/lexique/rehydratation/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-rehydratation-index-jsx" */),
  "component---src-data-pages-fr-lexique-selles-index-jsx": () => import("./../../../src/data/pages/fr/lexique/selles/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-selles-index-jsx" */),
  "component---src-data-pages-fr-lexique-sigmoidoscopie-index-jsx": () => import("./../../../src/data/pages/fr/lexique/sigmoidoscopie/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-sigmoidoscopie-index-jsx" */),
  "component---src-data-pages-fr-lexique-simethicone-index-jsx": () => import("./../../../src/data/pages/fr/lexique/simethicone/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-simethicone-index-jsx" */),
  "component---src-data-pages-fr-lexique-symptome-index-jsx": () => import("./../../../src/data/pages/fr/lexique/symptome/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-symptome-index-jsx" */),
  "component---src-data-pages-fr-lexique-systeme-immunitaire-index-jsx": () => import("./../../../src/data/pages/fr/lexique/systeme-immunitaire/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-systeme-immunitaire-index-jsx" */),
  "component---src-data-pages-fr-lexique-systeme-nerveux-central-index-jsx": () => import("./../../../src/data/pages/fr/lexique/systeme-nerveux-central/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-lexique-systeme-nerveux-central-index-jsx" */),
  "component---src-data-pages-fr-materiel-dinformation-diarrhee-brochure-patient-index-jsx": () => import("./../../../src/data/pages/fr/materiel-dinformation-diarrhee/brochure-patient/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-materiel-dinformation-diarrhee-brochure-patient-index-jsx" */),
  "component---src-data-pages-fr-materiel-dinformation-diarrhee-index-jsx": () => import("./../../../src/data/pages/fr/materiel-dinformation-diarrhee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-materiel-dinformation-diarrhee-index-jsx" */),
  "component---src-data-pages-fr-medicaments-imodium-imodium-capsules-index-jsx": () => import("./../../../src/data/pages/fr/medicaments-imodium/imodium-capsules/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-medicaments-imodium-imodium-capsules-index-jsx" */),
  "component---src-data-pages-fr-medicaments-imodium-imodium-duo-index-jsx": () => import("./../../../src/data/pages/fr/medicaments-imodium/imodium-duo/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-medicaments-imodium-imodium-duo-index-jsx" */),
  "component---src-data-pages-fr-medicaments-imodium-imodium-instant-index-jsx": () => import("./../../../src/data/pages/fr/medicaments-imodium/imodium-instant/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-medicaments-imodium-imodium-instant-index-jsx" */),
  "component---src-data-pages-fr-medicaments-imodium-index-jsx": () => import("./../../../src/data/pages/fr/medicaments-imodium/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-medicaments-imodium-index-jsx" */),
  "component---src-data-pages-fr-medicaments-imodium-quel-imodium-est-approprie-pour-vous-index-jsx": () => import("./../../../src/data/pages/fr/medicaments-imodium/quel-imodium-est-approprie-pour-vous/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-medicaments-imodium-quel-imodium-est-approprie-pour-vous-index-jsx" */),
  "component---src-data-pages-fr-mentions-legales-index-jsx": () => import("./../../../src/data/pages/fr/mentions-legales/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-mentions-legales-index-jsx" */),
  "component---src-data-pages-fr-plan-du-site-index-jsx": () => import("./../../../src/data/pages/fr/plan-du-site/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-plan-du-site-index-jsx" */),
  "component---src-data-pages-fr-politique-en-matiere-de-cookies-index-jsx": () => import("./../../../src/data/pages/fr/politique-en-matiere-de-cookies/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-politique-en-matiere-de-cookies-index-jsx" */),
  "component---src-data-pages-fr-respect-de-la-vie-privee-index-jsx": () => import("./../../../src/data/pages/fr/respect-de-la-vie-privee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-respect-de-la-vie-privee-index-jsx" */),
  "component---src-data-pages-fr-veelgestelde-vragen-diarree-ai-je-besoin-dune-prescription-pour-imodium-index-jsx": () => import("./../../../src/data/pages/fr/veelgestelde-vragen-diarree/ai-je-besoin-dune-prescription-pour-imodium/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-veelgestelde-vragen-diarree-ai-je-besoin-dune-prescription-pour-imodium-index-jsx" */),
  "component---src-data-pages-fr-veelgestelde-vragen-diarree-des-raisons-possibles-a-une-diarrhee-recurrente-index-jsx": () => import("./../../../src/data/pages/fr/veelgestelde-vragen-diarree/des-raisons-possibles-a-une-diarrhee-recurrente/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-veelgestelde-vragen-diarree-des-raisons-possibles-a-une-diarrhee-recurrente-index-jsx" */),
  "component---src-data-pages-fr-veelgestelde-vragen-diarree-est-ce-que-votre-estomac-est-sensible-a-la-nourriture-epicee-index-jsx": () => import("./../../../src/data/pages/fr/veelgestelde-vragen-diarree/est-ce-que-votre-estomac-est-sensible-a-la-nourriture-epicee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-veelgestelde-vragen-diarree-est-ce-que-votre-estomac-est-sensible-a-la-nourriture-epicee-index-jsx" */),
  "component---src-data-pages-fr-veelgestelde-vragen-diarree-les-enfants-peuvent-ils-utiliser-imodium-index-jsx": () => import("./../../../src/data/pages/fr/veelgestelde-vragen-diarree/les-enfants-peuvent-ils-utiliser-imodium/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-veelgestelde-vragen-diarree-les-enfants-peuvent-ils-utiliser-imodium-index-jsx" */),
  "component---src-data-pages-fr-veelgestelde-vragen-diarree-les-solutions-de-rehydratation-orale-traitent-elles-la-diarrhee-index-jsx": () => import("./../../../src/data/pages/fr/veelgestelde-vragen-diarree/les-solutions-de-rehydratation-orale-traitent-elles-la-diarrhee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-veelgestelde-vragen-diarree-les-solutions-de-rehydratation-orale-traitent-elles-la-diarrhee-index-jsx" */),
  "component---src-data-pages-fr-veelgestelde-vragen-diarree-nest-il-pas-preferable-de-laisser-la-diarrhee-suivre-son-cours-pour-se-deba-index-jsx": () => import("./../../../src/data/pages/fr/veelgestelde-vragen-diarree/nest-il-pas-preferable-de-laisser-la-diarrhee-suivre-son-cours-pour-se-deba/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-veelgestelde-vragen-diarree-nest-il-pas-preferable-de-laisser-la-diarrhee-suivre-son-cours-pour-se-deba-index-jsx" */),
  "component---src-data-pages-fr-veelgestelde-vragen-diarree-puis-je-utiliser-imodium-si-je-suis-enceinte-ou-si-jallaite-index-jsx": () => import("./../../../src/data/pages/fr/veelgestelde-vragen-diarree/puis-je-utiliser-imodium-si-je-suis-enceinte-ou-si-jallaite/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-veelgestelde-vragen-diarree-puis-je-utiliser-imodium-si-je-suis-enceinte-ou-si-jallaite-index-jsx" */),
  "component---src-data-pages-fr-veelgestelde-vragen-diarree-que-faire-si-mes-enfants-ont-la-diarrhee-index-jsx": () => import("./../../../src/data/pages/fr/veelgestelde-vragen-diarree/que-faire-si-mes-enfants-ont-la-diarrhee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-veelgestelde-vragen-diarree-que-faire-si-mes-enfants-ont-la-diarrhee-index-jsx" */),
  "component---src-data-pages-fr-veelgestelde-vragen-diarree-quel-traitement-puis-je-prendre-si-je-narrive-pas-a-avaler-de-comprimes-index-jsx": () => import("./../../../src/data/pages/fr/veelgestelde-vragen-diarree/quel-traitement-puis-je-prendre-si-je-narrive-pas-a-avaler-de-comprimes/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-veelgestelde-vragen-diarree-quel-traitement-puis-je-prendre-si-je-narrive-pas-a-avaler-de-comprimes-index-jsx" */),
  "component---src-data-pages-fr-veelgestelde-vragen-diarree-quest-ce-que-la-diarrhee-index-jsx": () => import("./../../../src/data/pages/fr/veelgestelde-vragen-diarree/quest-ce-que-la-diarrhee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-veelgestelde-vragen-diarree-quest-ce-que-la-diarrhee-index-jsx" */),
  "component---src-data-pages-hoe-werkt-imodium-instant-index-jsx": () => import("./../../../src/data/pages/hoe-werkt-imodium-instant/index.jsx" /* webpackChunkName: "component---src-data-pages-hoe-werkt-imodium-instant-index-jsx" */),
  "component---src-data-pages-hoe-werkt-imodium-instant-werkzame-stof-loperamide-index-jsx": () => import("./../../../src/data/pages/hoe-werkt-imodium-instant/werkzame-stof-loperamide/index.jsx" /* webpackChunkName: "component---src-data-pages-hoe-werkt-imodium-instant-werkzame-stof-loperamide-index-jsx" */),
  "component---src-data-pages-imodium-behandelen-diarree-imodium-duo-index-jsx": () => import("./../../../src/data/pages/imodium-behandelen-diarree/imodium-duo/index.jsx" /* webpackChunkName: "component---src-data-pages-imodium-behandelen-diarree-imodium-duo-index-jsx" */),
  "component---src-data-pages-imodium-behandelen-diarree-imodium-instant-index-jsx": () => import("./../../../src/data/pages/imodium-behandelen-diarree/imodium-instant/index.jsx" /* webpackChunkName: "component---src-data-pages-imodium-behandelen-diarree-imodium-instant-index-jsx" */),
  "component---src-data-pages-imodium-behandelen-diarree-imodiumreg-capsules-index-jsx": () => import("./../../../src/data/pages/imodium-behandelen-diarree/imodiumreg-capsules/index.jsx" /* webpackChunkName: "component---src-data-pages-imodium-behandelen-diarree-imodiumreg-capsules-index-jsx" */),
  "component---src-data-pages-imodium-behandelen-diarree-index-jsx": () => import("./../../../src/data/pages/imodium-behandelen-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-imodium-behandelen-diarree-index-jsx" */),
  "component---src-data-pages-imodium-behandelen-diarree-welke-imodium-is-de-juiste-index-jsx": () => import("./../../../src/data/pages/imodium-behandelen-diarree/welke-imodium-is-de-juiste/index.jsx" /* webpackChunkName: "component---src-data-pages-imodium-behandelen-diarree-welke-imodium-is-de-juiste-index-jsx" */),
  "component---src-data-pages-index-jsx": () => import("./../../../src/data/pages/index.jsx" /* webpackChunkName: "component---src-data-pages-index-jsx" */),
  "component---src-data-pages-infomateriaal-diaree-index-jsx": () => import("./../../../src/data/pages/infomateriaal-diaree/index.jsx" /* webpackChunkName: "component---src-data-pages-infomateriaal-diaree-index-jsx" */),
  "component---src-data-pages-infomateriaal-diaree-patientenbrochure-index-jsx": () => import("./../../../src/data/pages/infomateriaal-diaree/patientenbrochure/index.jsx" /* webpackChunkName: "component---src-data-pages-infomateriaal-diaree-patientenbrochure-index-jsx" */),
  "component---src-data-pages-privacybeleid-index-jsx": () => import("./../../../src/data/pages/privacybeleid/index.jsx" /* webpackChunkName: "component---src-data-pages-privacybeleid-index-jsx" */),
  "component---src-data-pages-sitemap-index-jsx": () => import("./../../../src/data/pages/sitemap/index.jsx" /* webpackChunkName: "component---src-data-pages-sitemap-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-diarree-zijn-gang-te-laten-gaan-om-van-de-microben-af-te-raken-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/diarree-zijn-gang-te-laten-gaan-om-van-de-microben-af-te-raken/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-diarree-zijn-gang-te-laten-gaan-om-van-de-microben-af-te-raken-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-gebruik-van-imodium-bij-zwangerschap-of-borstvoeding-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/gebruik-van-imodium-bij-zwangerschap-of-borstvoeding/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-gebruik-van-imodium-bij-zwangerschap-of-borstvoeding-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-geschikte-behandeling-diarree-zonder-inslikken-tabletten-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/geschikte-behandeling-diarree-zonder-inslikken-tabletten/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-geschikte-behandeling-diarree-zonder-inslikken-tabletten-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-heb-ik-een-voorschrift-nodig-voor-imodium-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/heb-ik-een-voorschrift-nodig-voor-imodium/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-heb-ik-een-voorschrift-nodig-voor-imodium-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-kan-imodium-verstopping-veroorzaken-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/kan-imodium-verstopping-veroorzaken/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-kan-imodium-verstopping-veroorzaken-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-moet-ik-diarree-behandelen-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/moet-ik-diarree-behandelen/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-moet-ik-diarree-behandelen-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-mogen-kinderen-imodium-gebruiken-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/mogen-kinderen-imodium-gebruiken/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-mogen-kinderen-imodium-gebruiken-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-oorzaak-terugkerende-diarree-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/oorzaak-terugkerende-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-oorzaak-terugkerende-diarree-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-schaamte-om-een-middel-tegen-diarree-te-vragen-wat-kan-je-doen-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/schaamte-om-een-middel-tegen-diarree-te-vragen-wat-kan-je-doen/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-schaamte-om-een-middel-tegen-diarree-te-vragen-wat-kan-je-doen-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-waarom-diarree-als-je-grote-hoeveelheden-eet-en-drinkt-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/waarom-diarree-als-je-grote-hoeveelheden-eet-en-drinkt/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-waarom-diarree-als-je-grote-hoeveelheden-eet-en-drinkt-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-waarom-krijg-ik-last-van-mijn-maag-wanneer-ik-zenuwachtig-ben-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/waarom-krijg-ik-last-van-mijn-maag-wanneer-ik-zenuwachtig-ben/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-waarom-krijg-ik-last-van-mijn-maag-wanneer-ik-zenuwachtig-ben-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-waarom-krijg-je-diarree-tijdens-je-maandstonden-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/waarom-krijg-je-diarree-tijdens-je-maandstonden/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-waarom-krijg-je-diarree-tijdens-je-maandstonden-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-wat-bij-krampen-opgeblazen-gevoel-winderigheid-bij-diarree-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/wat-bij-krampen-opgeblazen-gevoel-winderigheid-bij-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-wat-bij-krampen-opgeblazen-gevoel-winderigheid-bij-diarree-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-wat-is-de-oorzaak-van-acute-diarree-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/wat-is-de-oorzaak-van-acute-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-wat-is-de-oorzaak-van-acute-diarree-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-wat-is-diarree-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/wat-is-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-wat-is-diarree-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-wat-kan-je-eraan-doen-als-je-maag-reageert-op-sterk-gekruid-voedsel-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/wat-kan-je-eraan-doen-als-je-maag-reageert-op-sterk-gekruid-voedsel/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-wat-kan-je-eraan-doen-als-je-maag-reageert-op-sterk-gekruid-voedsel-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-wat-moet-ik-doen-als-mijn-kinderen-diarree-krijgen-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/wat-moet-ik-doen-als-mijn-kinderen-diarree-krijgen/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-wat-moet-ik-doen-als-mijn-kinderen-diarree-krijgen-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-zijn-orale-rehydratatieoplossingen-een-behandeling-voor-diarree-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/zijn-orale-rehydratatieoplossingen-een-behandeling-voor-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-zijn-orale-rehydratatieoplossingen-een-behandeling-voor-diarree-index-jsx" */),
  "component---src-data-pages-wettelijke-verklaring-index-jsx": () => import("./../../../src/data/pages/wettelijke-verklaring/index.jsx" /* webpackChunkName: "component---src-data-pages-wettelijke-verklaring-index-jsx" */),
  "component---src-data-pages-woordenlijst-abdominaal-index-jsx": () => import("./../../../src/data/pages/woordenlijst/abdominaal/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-abdominaal-index-jsx" */),
  "component---src-data-pages-woordenlijst-acute-diarree-index-jsx": () => import("./../../../src/data/pages/woordenlijst/acute-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-acute-diarree-index-jsx" */),
  "component---src-data-pages-woordenlijst-allergie-index-jsx": () => import("./../../../src/data/pages/woordenlijst/allergie/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-allergie-index-jsx" */),
  "component---src-data-pages-woordenlijst-antibiotica-index-jsx": () => import("./../../../src/data/pages/woordenlijst/antibiotica/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-antibiotica-index-jsx" */),
  "component---src-data-pages-woordenlijst-antispasmodisch-middel-index-jsx": () => import("./../../../src/data/pages/woordenlijst/antispasmodisch-middel/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-antispasmodisch-middel-index-jsx" */),
  "component---src-data-pages-woordenlijst-bacterien-index-jsx": () => import("./../../../src/data/pages/woordenlijst/bacterien/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-bacterien-index-jsx" */),
  "component---src-data-pages-woordenlijst-centraal-zenuwstelsel-index-jsx": () => import("./../../../src/data/pages/woordenlijst/centraal-zenuwstelsel/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-centraal-zenuwstelsel-index-jsx" */),
  "component---src-data-pages-woordenlijst-chronische-diarree-index-jsx": () => import("./../../../src/data/pages/woordenlijst/chronische-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-chronische-diarree-index-jsx" */),
  "component---src-data-pages-woordenlijst-constipatie-index-jsx": () => import("./../../../src/data/pages/woordenlijst/constipatie/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-constipatie-index-jsx" */),
  "component---src-data-pages-woordenlijst-darmen-index-jsx": () => import("./../../../src/data/pages/woordenlijst/darmen/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-darmen-index-jsx" */),
  "component---src-data-pages-woordenlijst-dehydratatie-index-jsx": () => import("./../../../src/data/pages/woordenlijst/dehydratatie/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-dehydratatie-index-jsx" */),
  "component---src-data-pages-woordenlijst-diarree-index-jsx": () => import("./../../../src/data/pages/woordenlijst/diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-diarree-index-jsx" */),
  "component---src-data-pages-woordenlijst-flatulentie-of-winderigheid-index-jsx": () => import("./../../../src/data/pages/woordenlijst/flatulentie-of-winderigheid/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-flatulentie-of-winderigheid-index-jsx" */),
  "component---src-data-pages-woordenlijst-gastro-enteritis-of-buikgriep-index-jsx": () => import("./../../../src/data/pages/woordenlijst/gastro-enteritis-of-buikgriep/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-gastro-enteritis-of-buikgriep-index-jsx" */),
  "component---src-data-pages-woordenlijst-immuunsysteem-index-jsx": () => import("./../../../src/data/pages/woordenlijst/immuunsysteem/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-immuunsysteem-index-jsx" */),
  "component---src-data-pages-woordenlijst-index-jsx": () => import("./../../../src/data/pages/woordenlijst/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-index-jsx" */),
  "component---src-data-pages-woordenlijst-laxeermiddelen-index-jsx": () => import("./../../../src/data/pages/woordenlijst/laxeermiddelen/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-laxeermiddelen-index-jsx" */),
  "component---src-data-pages-woordenlijst-loperamide-index-jsx": () => import("./../../../src/data/pages/woordenlijst/loperamide/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-loperamide-index-jsx" */),
  "component---src-data-pages-woordenlijst-menstruatie-index-jsx": () => import("./../../../src/data/pages/woordenlijst/menstruatie/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-menstruatie-index-jsx" */),
  "component---src-data-pages-woordenlijst-misselijkheid-index-jsx": () => import("./../../../src/data/pages/woordenlijst/misselijkheid/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-misselijkheid-index-jsx" */),
  "component---src-data-pages-woordenlijst-norovirus-index-jsx": () => import("./../../../src/data/pages/woordenlijst/norovirus/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-norovirus-index-jsx" */),
  "component---src-data-pages-woordenlijst-nutrienten-of-voedingsstoffen-index-jsx": () => import("./../../../src/data/pages/woordenlijst/nutrienten-of-voedingsstoffen/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-nutrienten-of-voedingsstoffen-index-jsx" */),
  "component---src-data-pages-woordenlijst-opgeblazen-gevoel-index-jsx": () => import("./../../../src/data/pages/woordenlijst/opgeblazen-gevoel/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-opgeblazen-gevoel-index-jsx" */),
  "component---src-data-pages-woordenlijst-peristaltische-beweging-index-jsx": () => import("./../../../src/data/pages/woordenlijst/peristaltische-beweging/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-peristaltische-beweging-index-jsx" */),
  "component---src-data-pages-woordenlijst-rehydratatie-index-jsx": () => import("./../../../src/data/pages/woordenlijst/rehydratatie/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-rehydratatie-index-jsx" */),
  "component---src-data-pages-woordenlijst-sigmoidoscopie-index-jsx": () => import("./../../../src/data/pages/woordenlijst/sigmoidoscopie/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-sigmoidoscopie-index-jsx" */),
  "component---src-data-pages-woordenlijst-simeticon-index-jsx": () => import("./../../../src/data/pages/woordenlijst/simeticon/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-simeticon-index-jsx" */),
  "component---src-data-pages-woordenlijst-slijm-index-jsx": () => import("./../../../src/data/pages/woordenlijst/slijm/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-slijm-index-jsx" */),
  "component---src-data-pages-woordenlijst-stoelgang-index-jsx": () => import("./../../../src/data/pages/woordenlijst/stoelgang/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-stoelgang-index-jsx" */),
  "component---src-data-pages-woordenlijst-symmptoom-index-jsx": () => import("./../../../src/data/pages/woordenlijst/symmptoom/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-symmptoom-index-jsx" */)
}

